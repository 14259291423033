import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
                <div className="inner">
                    <ul className="icons">
                        <li><a href="https://www.instagram.com/diegoernani.dev/" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
                        <li><a href="https://www.linkedin.com/in/diegoernani/" className="icon fa-linkedin"><span className="label">Linkedin</span></a></li>
                        <li><a href="https://github.com/diegoernani" className="icon fa-github"><span className="label">Github</span></a></li>  
                        <li><a href={"mailto:" + "contrate@diegoernani.pro"} className="icon fa-envelope-o"><span className="label">Email</span></a></li>
                        <li><a href="https://api.whatsapp.com/send?phone=5527996360011&text=V%C3%AD%20seu%20portif%C3%B3lio%20no%20site%20e%20gostaria%20de%20falar%20com%20voc%C3%AA." className="icon fa-whatsapp"><span className="label">Whatsapp</span></a></li>
                        <li><a href="https://dev.to/diegoernani" className = "fab fa-dev"> <span className="label">DEV.TO</span></a></li>
                    </ul>
                    <ul className="copyright">
                        <li>&copy; Diego Ernani 2020</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Footer
