import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="#" className="image avatar"><img src={avatar} alt="" /></a>
                    <h4>
                    Meu nome é <br/>
                    <strong> Diego Ernani </strong>
                    e neste espaço <br/ >
                     vou publicar meu portifólio <br/ >
                    e experiências profissionais<br/>
                    </h4>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
