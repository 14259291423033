import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
//import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'
import thumb07 from '../assets/images/thumbs/07.jpg'
import thumb08 from '../assets/images/thumbs/08.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'
import full05 from '../assets/images/fulls/05.jpg'
import full06 from '../assets/images/fulls/06.jpg'
import full07 from '../assets/images/fulls/07.jpg'
import full08 from '../assets/images/fulls/08.jpg'

const DEFAULT_IMAGES = [
    { id: '1', source: full01, thumbnail: thumb01, caption: '', description: 'Um.'},
    { id: '2', source: full02, thumbnail: thumb02, caption: '', description: 'Dois.'},
    { id: '3', source: full03, thumbnail: thumb03, caption: '', description: 'Três.'},
    { id: '4', source: full04, thumbnail: thumb04, caption: '', description: 'Quatro.'},
    { id: '5', source: full05, thumbnail: thumb05, caption: '', description: 'Cinco.'},
    { id: '6', source: full06, thumbnail: thumb06, caption: '', description: 'Seis.'},
    { id: '7', source: full07, thumbnail: thumb07, caption: '', description: 'Sete.'},
    { id: '8', source: full08, thumbnail: thumb08, caption: '', description: 'Oito.'}
];

class HomeIndex extends React.Component {

    render() {
        const siteTitle = "Diego Ernani"
        const siteDescription = "portifólio profissional"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>Quem sou </h2>
                        </header>
                        <p>Mineiro de Belo Horizonte. Já viajei de BH a Santa Teresa no Espírito Santo em uma Honda Biz 125 e ali fixei morada no ano de 2008. 
                        Em 2009 me mudei para cidade de Colatina e iniciei minha carreira como técnico em sistemas na empresa Exata Sistemas. 
                        No ano de 2012 me formei em Análise e Desenvolvimento de Sistemas e logo
                        surgiu uma oportunidade de ser programador de sistemas e claro, abracei a oportunidade. Iniciei com Delphi(Object Pascal), hoje minha stack principal.
                         Trabalhei por 10 anos nesta empresa, vivendo bons tempos de aprendizado e troca de experiências. 
                         Em 2019 busquei novos horizontes e hoje não diferente do passado tenho aprendido muito na empresa Matriz Sistemas. Em paralelo as empresas, estudei outras linguagens e 
                         dentre várias tenho me interessado e praticado JavaScript, principalmente stack React a qual tenho colocado em prática alguns projetos pessoais que vou compartilhar aqui.
                         Também vou colocar outros trabalhos desenvolvidos em Delphi e meus projetos como design.   </p>
                        {/* <ul className="actions">
                            <li><a href="#" className="button">Learn More</a></li>
                        </ul> */}
                    </section>

                    <section id="two">
                        <h2>Projetos Pessoais e Profissionais</h2>

                        <Gallery images={DEFAULT_IMAGES.map(({ id, source, thumbnail, caption, description }) => ({
                            source,
                            thumbnail,
                            caption,
                            description
                        }))} />

                        {/* <ul className="actions">
                            <li><a href="#" className="button"> Portfolio completo</a></li>
                        </ul> */}
                    </section>

                    {/* <section id="three"> */}
                        {/* <h2>Fale comigo</h2>
                        <p>Se chegou até aqui, com certeza você precisa de um site, aplicativo, um sistema personalizado ou talvez serviços design. Já estou esperando seu contato.</p> */}
                        {/* <div className="row"> */}
                            {/* <div className="8u 12u$(small)">
                                <form method="post" action="#">
                                    <div className="row uniform 50%">
                                        <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                        <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                        <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                                    </div>
                                </form>
                                <ul className="actions">
                                    <li><input type="submit" value="Enviar" /></li>
                                </ul>
                            </div> */}
                            {/* <div className="4u 12u$(small)">
                                <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Endereço</span></h3>
                                        Colatina, ES<br />
                                        Brasil
                                    </li>
                                    <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        (27) 996-360-011
                                    </li>
                                    <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="#">contrate@diegoernani.pro</a>
                                    </li>
                                </ul>
                            </div> */}
                            
                        {/* </div> */}
                    {/* </section> */}

                </div>

            </Layout>
        )
    }
}

export default HomeIndex